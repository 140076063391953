<template>
  <div>
    <vx-card class="mb-4">
      <h4 class="mb-4">
        {{ $t(resources.URLs.i18n) || resources.URLs.name }}
      </h4>

      <div class="vx-row">
        <div class="vx-col w-full md:w-1/3 mb-base mt-5">
          <label class="vs-input--label">Presentación - Visiontravel</label>
          <vs-input
            v-model="urlVisiontravel"
            class="w-full"
            icon-pack="feather"
            icon="icon-link"
            icon-no-border
            readonly
          />
        </div>
        <div class="vx-col w-full md:w-1/6 mb-base mt-8">
          <vs-button
            class="vs-button-1"
            v-clipboard:copy="urlVisiontravel"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
            >{{ $t(resources.Copy.i18n) || resources.Copy.name }}</vs-button
          >
        </div>
        <div class="vx-col w-full md:w-1/3 mb-base mt-5">
          <label class="vs-input--label">Seguimiento - Visiontravel</label>
          <vs-input
            v-model="urlVisiontravelTracing"
            class="w-full"
            icon-pack="feather"
            icon="icon-link"
            icon-no-border
            readonly
          />
        </div>
        <div class="vx-col w-full md:w-1/6 mb-base mt-8">
          <vs-button
            class="vs-button-1"
            v-clipboard:copy="urlVisiontravelTracing"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
            >{{ $t(resources.Copy.i18n) || resources.Copy.name }}</vs-button
          >
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col w-full md:w-1/3 mb-base mt-5">
          <label class="vs-input--label">Presentación - Visionbiz</label>
          <vs-input
            v-model="urlInfo"
            class="w-full"
            icon-pack="feather"
            icon="icon-link"
            icon-no-border
            readonly
          />
        </div>
        <div class="vx-col w-full md:w-1/6 mb-base mt-8">
          <vs-button
            class="vs-button-1"
            v-clipboard:copy="urlInfo"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
            >{{ $t(resources.Copy.i18n) || resources.Copy.name }}</vs-button
          >
        </div>
        <div class="vx-col w-full md:w-1/3 mb-base mt-5">
          <label class="vs-input--label">Seguimiento - Visionbiz</label>
          <vs-input
            v-model="urlInfoTracing"
            class="w-full"
            icon-pack="feather"
            icon="icon-link"
            icon-no-border
            readonly
          />
        </div>
        <div class="vx-col w-full md:w-1/6 mb-base mt-8">
          <vs-button
            class="vs-button-1"
            v-clipboard:copy="urlInfoTracing"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
            >{{ $t(resources.Copy.i18n) || resources.Copy.name }}</vs-button
          >
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col w-full md:w-1/3 mb-base mt-5">
          <label class="vs-input--label">Presentación - Realvision</label>
          <vs-input
            v-model="urlRealvision"
            class="w-full"
            icon-pack="feather"
            icon="icon-link"
            icon-no-border
            readonly
          />
        </div>
        <div class="vx-col w-full md:w-1/6 mb-base mt-8">
          <vs-button
            class="vs-button-1"
            v-clipboard:copy="urlRealvision"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
            >{{ $t(resources.Copy.i18n) || resources.Copy.name }}</vs-button
          >
        </div>
        <div class="vx-col w-full md:w-1/3 mb-base mt-5">
          <label class="vs-input--label">Seguimiento - Realvision</label>
          <vs-input
            v-model="urlRealvisionTracing"
            class="w-full"
            icon-pack="feather"
            icon="icon-link"
            icon-no-border
            readonly
          />
        </div>
        <div class="vx-col w-full md:w-1/6 mb-base mt-8">
          <vs-button
            class="vs-button-1"
            v-clipboard:copy="urlRealvisionTracing"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
            >{{ $t(resources.Copy.i18n) || resources.Copy.name }}</vs-button
          >
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col w-full md:w-1/3 mb-base mt-5">
          <label class="vs-input--label">Presentación - Safeguard</label>
          <vs-input
            v-model="urlSafeguard"
            class="w-full"
            icon-pack="feather"
            icon="icon-link"
            icon-no-border
            readonly
          />
        </div>
        <div class="vx-col w-full md:w-1/6 mb-base mt-8">
          <vs-button
            class="vs-button-1"
            v-clipboard:copy="urlSafeguard"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
            >{{ $t(resources.Copy.i18n) || resources.Copy.name }}</vs-button
          >
        </div>
        <div class="vx-col w-full md:w-1/3 mb-base mt-5">
          <label class="vs-input--label">Seguimiento - Safeguard</label>
          <vs-input
            v-model="urlSafeguardTracing"
            class="w-full"
            icon-pack="feather"
            icon="icon-link"
            icon-no-border
            readonly
          />
        </div>
        <div class="vx-col w-full md:w-1/6 mb-base mt-8">
          <vs-button
            class="vs-button-1"
            v-clipboard:copy="urlSafeguardTracing"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
            >{{ $t(resources.Copy.i18n) || resources.Copy.name }}</vs-button
          >
        </div>
      </div>
    </vx-card>
  </div>
</template>

<script>
import resources from "@/i18n/resources.js";
import alerts from "@/i18n/alerts.js";
import * as lang from "vuejs-datepicker/src/locale";
import "@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss";

export default {
  data() {
    return {
      resources: resources,
      alerts: alerts,
      language: this.$i18n.locale,
      languages: lang,
      itaCode: JSON.parse(localStorage.getItem("userInfo")).itaCode,
      urlVisiontravel: `https://visiontravel.visionbiz.net/${
        JSON.parse(localStorage.getItem("userInfo")).itaCode
      }`,
      urlVisiontravelTracing: `https://visiontravel.visionbiz.net/home`,
      urlInfo: `https://info.visionbiz.net/${
        JSON.parse(localStorage.getItem("userInfo")).itaCode
      }`,
      urlInfoTracing: `https://info.visionbiz.net/home`,
      urlRealvision: `https://realvision.visionbiz.net/${
        JSON.parse(localStorage.getItem("userInfo")).itaCode
      }`,
      urlRealvisionTracing: `https://realvision.visionbiz.net/home`,
      urlSafeguard: `https://safeguard.visionbiz.net/${
        JSON.parse(localStorage.getItem("userInfo")).itaCode
      }`,
      urlSafeguardTracing: `https://safeguard.visionbiz.net/home`
    };
  },

  methods: {
    onCopy() {
      this.$vs.notify({
        title: this.$i18n.t("Success"),
        text: this.$i18n.t("MsgCopy"),
        color: "success",
        iconPack: "feather",
        position: "top-center",
        icon: "icon-check-circle"
      });
    },

    onError() {
      this.$vs.notify({
        title: this.$i18n.t("Error"),
        text: this.$i18n.t("MsgError"),
        color: "danger",
        iconPack: "feather",
        position: "top-center",
        icon: "icon-alert-circle"
      });
    }
  }
};
</script>

<style>
.vs-button-1 {
  margin-top: 9px;
}

.vs-button-2 {
  margin-top: 18px;
}
</style>
