var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("vx-card", { staticClass: "mb-4" }, [
        _c("h4", { staticClass: "mb-4" }, [
          _vm._v(
            "\n      " +
              _vm._s(
                _vm.$t(_vm.resources.URLs.i18n) || _vm.resources.URLs.name
              ) +
              "\n    "
          )
        ]),
        _c("div", { staticClass: "vx-row" }, [
          _c(
            "div",
            { staticClass: "vx-col w-full md:w-1/3 mb-base mt-5" },
            [
              _c("label", { staticClass: "vs-input--label" }, [
                _vm._v("Presentación - Visiontravel")
              ]),
              _c("vs-input", {
                staticClass: "w-full",
                attrs: {
                  "icon-pack": "feather",
                  icon: "icon-link",
                  "icon-no-border": "",
                  readonly: ""
                },
                model: {
                  value: _vm.urlVisiontravel,
                  callback: function($$v) {
                    _vm.urlVisiontravel = $$v
                  },
                  expression: "urlVisiontravel"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "vx-col w-full md:w-1/6 mb-base mt-8" },
            [
              _c(
                "vs-button",
                {
                  directives: [
                    {
                      name: "clipboard",
                      rawName: "v-clipboard:copy",
                      value: _vm.urlVisiontravel,
                      expression: "urlVisiontravel",
                      arg: "copy"
                    },
                    {
                      name: "clipboard",
                      rawName: "v-clipboard:success",
                      value: _vm.onCopy,
                      expression: "onCopy",
                      arg: "success"
                    },
                    {
                      name: "clipboard",
                      rawName: "v-clipboard:error",
                      value: _vm.onError,
                      expression: "onError",
                      arg: "error"
                    }
                  ],
                  staticClass: "vs-button-1"
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t(_vm.resources.Copy.i18n) || _vm.resources.Copy.name
                    )
                  )
                ]
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "vx-col w-full md:w-1/3 mb-base mt-5" },
            [
              _c("label", { staticClass: "vs-input--label" }, [
                _vm._v("Seguimiento - Visiontravel")
              ]),
              _c("vs-input", {
                staticClass: "w-full",
                attrs: {
                  "icon-pack": "feather",
                  icon: "icon-link",
                  "icon-no-border": "",
                  readonly: ""
                },
                model: {
                  value: _vm.urlVisiontravelTracing,
                  callback: function($$v) {
                    _vm.urlVisiontravelTracing = $$v
                  },
                  expression: "urlVisiontravelTracing"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "vx-col w-full md:w-1/6 mb-base mt-8" },
            [
              _c(
                "vs-button",
                {
                  directives: [
                    {
                      name: "clipboard",
                      rawName: "v-clipboard:copy",
                      value: _vm.urlVisiontravelTracing,
                      expression: "urlVisiontravelTracing",
                      arg: "copy"
                    },
                    {
                      name: "clipboard",
                      rawName: "v-clipboard:success",
                      value: _vm.onCopy,
                      expression: "onCopy",
                      arg: "success"
                    },
                    {
                      name: "clipboard",
                      rawName: "v-clipboard:error",
                      value: _vm.onError,
                      expression: "onError",
                      arg: "error"
                    }
                  ],
                  staticClass: "vs-button-1"
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t(_vm.resources.Copy.i18n) || _vm.resources.Copy.name
                    )
                  )
                ]
              )
            ],
            1
          )
        ]),
        _c("div", { staticClass: "vx-row" }, [
          _c(
            "div",
            { staticClass: "vx-col w-full md:w-1/3 mb-base mt-5" },
            [
              _c("label", { staticClass: "vs-input--label" }, [
                _vm._v("Presentación - Visionbiz")
              ]),
              _c("vs-input", {
                staticClass: "w-full",
                attrs: {
                  "icon-pack": "feather",
                  icon: "icon-link",
                  "icon-no-border": "",
                  readonly: ""
                },
                model: {
                  value: _vm.urlInfo,
                  callback: function($$v) {
                    _vm.urlInfo = $$v
                  },
                  expression: "urlInfo"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "vx-col w-full md:w-1/6 mb-base mt-8" },
            [
              _c(
                "vs-button",
                {
                  directives: [
                    {
                      name: "clipboard",
                      rawName: "v-clipboard:copy",
                      value: _vm.urlInfo,
                      expression: "urlInfo",
                      arg: "copy"
                    },
                    {
                      name: "clipboard",
                      rawName: "v-clipboard:success",
                      value: _vm.onCopy,
                      expression: "onCopy",
                      arg: "success"
                    },
                    {
                      name: "clipboard",
                      rawName: "v-clipboard:error",
                      value: _vm.onError,
                      expression: "onError",
                      arg: "error"
                    }
                  ],
                  staticClass: "vs-button-1"
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t(_vm.resources.Copy.i18n) || _vm.resources.Copy.name
                    )
                  )
                ]
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "vx-col w-full md:w-1/3 mb-base mt-5" },
            [
              _c("label", { staticClass: "vs-input--label" }, [
                _vm._v("Seguimiento - Visionbiz")
              ]),
              _c("vs-input", {
                staticClass: "w-full",
                attrs: {
                  "icon-pack": "feather",
                  icon: "icon-link",
                  "icon-no-border": "",
                  readonly: ""
                },
                model: {
                  value: _vm.urlInfoTracing,
                  callback: function($$v) {
                    _vm.urlInfoTracing = $$v
                  },
                  expression: "urlInfoTracing"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "vx-col w-full md:w-1/6 mb-base mt-8" },
            [
              _c(
                "vs-button",
                {
                  directives: [
                    {
                      name: "clipboard",
                      rawName: "v-clipboard:copy",
                      value: _vm.urlInfoTracing,
                      expression: "urlInfoTracing",
                      arg: "copy"
                    },
                    {
                      name: "clipboard",
                      rawName: "v-clipboard:success",
                      value: _vm.onCopy,
                      expression: "onCopy",
                      arg: "success"
                    },
                    {
                      name: "clipboard",
                      rawName: "v-clipboard:error",
                      value: _vm.onError,
                      expression: "onError",
                      arg: "error"
                    }
                  ],
                  staticClass: "vs-button-1"
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t(_vm.resources.Copy.i18n) || _vm.resources.Copy.name
                    )
                  )
                ]
              )
            ],
            1
          )
        ]),
        _c("div", { staticClass: "vx-row" }, [
          _c(
            "div",
            { staticClass: "vx-col w-full md:w-1/3 mb-base mt-5" },
            [
              _c("label", { staticClass: "vs-input--label" }, [
                _vm._v("Presentación - Realvision")
              ]),
              _c("vs-input", {
                staticClass: "w-full",
                attrs: {
                  "icon-pack": "feather",
                  icon: "icon-link",
                  "icon-no-border": "",
                  readonly: ""
                },
                model: {
                  value: _vm.urlRealvision,
                  callback: function($$v) {
                    _vm.urlRealvision = $$v
                  },
                  expression: "urlRealvision"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "vx-col w-full md:w-1/6 mb-base mt-8" },
            [
              _c(
                "vs-button",
                {
                  directives: [
                    {
                      name: "clipboard",
                      rawName: "v-clipboard:copy",
                      value: _vm.urlRealvision,
                      expression: "urlRealvision",
                      arg: "copy"
                    },
                    {
                      name: "clipboard",
                      rawName: "v-clipboard:success",
                      value: _vm.onCopy,
                      expression: "onCopy",
                      arg: "success"
                    },
                    {
                      name: "clipboard",
                      rawName: "v-clipboard:error",
                      value: _vm.onError,
                      expression: "onError",
                      arg: "error"
                    }
                  ],
                  staticClass: "vs-button-1"
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t(_vm.resources.Copy.i18n) || _vm.resources.Copy.name
                    )
                  )
                ]
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "vx-col w-full md:w-1/3 mb-base mt-5" },
            [
              _c("label", { staticClass: "vs-input--label" }, [
                _vm._v("Seguimiento - Realvision")
              ]),
              _c("vs-input", {
                staticClass: "w-full",
                attrs: {
                  "icon-pack": "feather",
                  icon: "icon-link",
                  "icon-no-border": "",
                  readonly: ""
                },
                model: {
                  value: _vm.urlRealvisionTracing,
                  callback: function($$v) {
                    _vm.urlRealvisionTracing = $$v
                  },
                  expression: "urlRealvisionTracing"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "vx-col w-full md:w-1/6 mb-base mt-8" },
            [
              _c(
                "vs-button",
                {
                  directives: [
                    {
                      name: "clipboard",
                      rawName: "v-clipboard:copy",
                      value: _vm.urlRealvisionTracing,
                      expression: "urlRealvisionTracing",
                      arg: "copy"
                    },
                    {
                      name: "clipboard",
                      rawName: "v-clipboard:success",
                      value: _vm.onCopy,
                      expression: "onCopy",
                      arg: "success"
                    },
                    {
                      name: "clipboard",
                      rawName: "v-clipboard:error",
                      value: _vm.onError,
                      expression: "onError",
                      arg: "error"
                    }
                  ],
                  staticClass: "vs-button-1"
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t(_vm.resources.Copy.i18n) || _vm.resources.Copy.name
                    )
                  )
                ]
              )
            ],
            1
          )
        ]),
        _c("div", { staticClass: "vx-row" }, [
          _c(
            "div",
            { staticClass: "vx-col w-full md:w-1/3 mb-base mt-5" },
            [
              _c("label", { staticClass: "vs-input--label" }, [
                _vm._v("Presentación - Safeguard")
              ]),
              _c("vs-input", {
                staticClass: "w-full",
                attrs: {
                  "icon-pack": "feather",
                  icon: "icon-link",
                  "icon-no-border": "",
                  readonly: ""
                },
                model: {
                  value: _vm.urlSafeguard,
                  callback: function($$v) {
                    _vm.urlSafeguard = $$v
                  },
                  expression: "urlSafeguard"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "vx-col w-full md:w-1/6 mb-base mt-8" },
            [
              _c(
                "vs-button",
                {
                  directives: [
                    {
                      name: "clipboard",
                      rawName: "v-clipboard:copy",
                      value: _vm.urlSafeguard,
                      expression: "urlSafeguard",
                      arg: "copy"
                    },
                    {
                      name: "clipboard",
                      rawName: "v-clipboard:success",
                      value: _vm.onCopy,
                      expression: "onCopy",
                      arg: "success"
                    },
                    {
                      name: "clipboard",
                      rawName: "v-clipboard:error",
                      value: _vm.onError,
                      expression: "onError",
                      arg: "error"
                    }
                  ],
                  staticClass: "vs-button-1"
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t(_vm.resources.Copy.i18n) || _vm.resources.Copy.name
                    )
                  )
                ]
              )
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "vx-col w-full md:w-1/3 mb-base mt-5" },
            [
              _c("label", { staticClass: "vs-input--label" }, [
                _vm._v("Seguimiento - Safeguard")
              ]),
              _c("vs-input", {
                staticClass: "w-full",
                attrs: {
                  "icon-pack": "feather",
                  icon: "icon-link",
                  "icon-no-border": "",
                  readonly: ""
                },
                model: {
                  value: _vm.urlSafeguardTracing,
                  callback: function($$v) {
                    _vm.urlSafeguardTracing = $$v
                  },
                  expression: "urlSafeguardTracing"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "vx-col w-full md:w-1/6 mb-base mt-8" },
            [
              _c(
                "vs-button",
                {
                  directives: [
                    {
                      name: "clipboard",
                      rawName: "v-clipboard:copy",
                      value: _vm.urlSafeguardTracing,
                      expression: "urlSafeguardTracing",
                      arg: "copy"
                    },
                    {
                      name: "clipboard",
                      rawName: "v-clipboard:success",
                      value: _vm.onCopy,
                      expression: "onCopy",
                      arg: "success"
                    },
                    {
                      name: "clipboard",
                      rawName: "v-clipboard:error",
                      value: _vm.onError,
                      expression: "onError",
                      arg: "error"
                    }
                  ],
                  staticClass: "vs-button-1"
                },
                [
                  _vm._v(
                    _vm._s(
                      _vm.$t(_vm.resources.Copy.i18n) || _vm.resources.Copy.name
                    )
                  )
                ]
              )
            ],
            1
          )
        ])
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }